<template>
  <section>
    <v-container>
      <!-- This is for larger screen devices -->
      <v-sheet class="pa-4 rounded-lg hidden-sm-and-down">
        <v-toolbar flat class="mb-10">
          <v-toolbar-title>
            {{ activeCalendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small class="ma-2" @click="doPreviousMonth" color="primary">
            <font-awesome-icon size="lg" :icon="['fas', 'arrow-left']" fixed-width></font-awesome-icon>
          </v-btn>
          <v-btn class="ma-2" @click="doCurrentMonth" color="primary">
            Today
          </v-btn>
          <v-btn small class="ma-2" @click="doNextMonth" color="primary">
            <font-awesome-icon size="lg" :icon="['fas', 'arrow-right']" fixed-width></font-awesome-icon>
          </v-btn>
        </v-toolbar>
        <v-calendar
          v-model="calendar"
          :weekdays="[0, 1, 2, 3, 4, 5, 6]"
          type="month"
          ref="calendar"
          :events="calendarEvents"
          @change="doLoad"
          @click:event="showEvent"
          style="min-height: 600px;"
          :event-height="40"
        >
          <template v-slot:event="{ event }">
            <div class="mx-1">
              <div v-if="event.type === 'course'">
                <div>
                  <span class="font-weight-bold">
                    {{ $moment(event.start).format('h:mm A') }}
                  </span>
                    <span style="float:right;">
                    <font-awesome-icon size="sm" :icon="['fas', event.location.icon]" fixed-width></font-awesome-icon>
                    <span class="hidden-lg-and-down pl-1">{{ event.location.name }}</span>
                  </span>
                </div>
                <div>
                  <span>
                    <v-img style="display: inline-block;" contain :src="event.organization.logoUrl" width="12"></v-img>
                  </span>
                  <span class="body-2">
                  {{ event.name }}
                  </span>
                </div>
              </div>
              <div v-if="event.type === 'trip'">
                <div>
                  <span class="font-weight-bold">
                    Trip: {{ event.name }}
                  </span>
                  <span class="body-2">
                   {{ $moment(event.start).format('ll') }} to {{ $moment(event.end).format('ll') }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </v-calendar>
        <v-menu
          v-model="isSelectedEventOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card max-width="250" v-if="selectedEvent.type === 'course'">
            <v-card-title class="pb-1 text-center">
              <v-avatar size="32" style="width: 100%" v-if="selectedEvent.organization">
                <v-img contain :src="selectedEvent.organization.logoUrl"></v-img>
              </v-avatar>
              <div class="my-2 text-h6" v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ selectedEvent.name }}</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="4">
                  {{ $moment(selectedEvent.start).format('h:mm A') }}
                </v-col>
                <v-col align="right" v-if="selectedEvent.location">
                  <font-awesome-icon size="sm" :icon="['fas', selectedEvent.location.icon]" fixed-width></font-awesome-icon>
                  {{ selectedEvent.location.name }}
                </v-col>
              </v-row>
              <div class="mt-2">
                <v-btn block color="primary" @click="doGoToCourse(selectedEvent)">
                  View Course
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
          <v-card max-width="250" v-if="selectedEvent.type === 'trip'">
            <v-card-title class="pb-1 text-center">
              <div class="my-2 text-h6" v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ selectedEvent.name }}</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div>
                {{ $moment(selectedEvent.start).format('ll') }} to {{ $moment(selectedEvent.end).format('ll') }}
              </div>
              <div class="mt-2">
                <v-btn block color="primary" @click="doGoToTrip(selectedEvent)">
                  View Trip
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-sheet>
      <!-- This is for mobile -->
      <div class="text-center hidden-md-and-up my-3">
        <v-chip class="mx-2 px-4">
          {{ $moment(activeCalendar.start).format('LL') }}
        </v-chip>
        to
        <v-chip class="mx-2 px-4">
          {{ $moment(activeCalendar.end).format('LL') }}
        </v-chip>
        <div class="mt-4">
          <v-btn small class="pa-0 mx-4" @click="doPreviousMonth">
            <font-awesome-icon size="sm" :icon="['fas', 'arrow-left']" fixed-width></font-awesome-icon>
          </v-btn>
          <v-btn small class="pa-4 mx-1" @click="doCurrentMonth">
            Today
          </v-btn>
          <v-btn small class="pa-0 mx-4" @click="doNextMonth">
            <font-awesome-icon size="sm" :icon="['fas', 'arrow-right']" fixed-width></font-awesome-icon>
          </v-btn>
        </div>
      </div>
      <div class="hidden-md-and-up my-10 text-center" v-if="calendarEvents.length === 0">
        <span class="friendly-2">
          Nothing scheduled
        </span>
      </div>
      <v-card class="hidden-md-and-up my-2" v-for="course in groupedCourseEvents" :key="course.groupUuid">
        <v-card-title class="pb-1 text-center">
          <v-avatar size="32" style="width: 100%">
            <v-img contain :src="course.organization.logoUrl"></v-img>
          </v-avatar>
          <div class="my-2 text-h6" v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ course.name }}</div>
        </v-card-title>
        <v-card-text>
          <div>
            <v-list>
            <v-divider></v-divider>
            <span v-for="(event, indexB) in course.events" :key="indexB">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $moment(event.start).format('llll') }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <font-awesome-icon size="sm" :icon="['fas', event.location.icon]" fixed-width></font-awesome-icon>
                    <span class="ml-2">{{ event.location.name }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </span>
            </v-list>
          </div>
          <v-btn block class="mt-2" color="primary" @click="doGoToCourse(selectedEvent)">
            View Course
          </v-btn>
        </v-card-text>
      </v-card>

      <div class="mt-4 mx-4">
        <v-card outlined flat>
          <v-card-text>
            <span class="friendly-2 pa-4">
              FAQ
            </span>
              <v-expansion-panels class="mt-4">
                <v-expansion-panel v-for="(row, index) in faq" :key="index">
                  <v-expansion-panel-header>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <span class="friendly-2">
                            {{ row.question }}
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content class="friendly-1">
                            {{ row.content }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </section>
</template>

<script>

export default {
  name: 'Calendar',
  data: () => ({
    calendar: '',
    activeCalendar: {
      title: String(),
      start: null,
      end: null
    },
    isSelectedEventOpen: false,
    selectedEvent: { },
    selectedElement: null,
    events: {
      courses: [],
      trips: []
    },
    faq: [
      {
        question: 'How do I choose the dates for the course I want to join?',
        content: 'Once you purchase a course online, an employee at Sea2Sea will contact you and find the dates that works best for you within each parties schedule.'
      },
      {
        question: 'I don\'t see a date for the course I\'m interested in?',
        content: 'No Problem! We offer private instruction for almost all our courses. You can easily purchase a course online, then get in contact with our staff.'
      }
    ]
  }),
  methods: {
    doLoad () {
      this.doUpdateFromCalendar()
      this.doLoadCourseEvents()
      this.doLoadTripEvents()
    },
    doLoadCourseEvents () {
      this.$services.course.event.doReadByDateRange(this.activeCalendar.start, this.activeCalendar.end).then(courseEvents => {
        this.events.courses = courseEvents
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      })
    },
    doLoadTripEvents () {
      this.$services.trip.doGetAllActive().then(trips => {
        this.events.trips = trips
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      })
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.isSelectedEventOpen = true
        }))
      }

      // Double check this.
      if (this.isSelectedEventOpen) {
        this.isSelectedEventOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    doGoToCourse: function (event) {
      this.$router.push({ name: 'view-course', params: { id: event.courseId } })
    },
    doGoToTrip: function (event) {
      this.$router.push({ name: 'view-trip', params: { id: event.tripId } })
    },
    doNextMonth () {
      this.$refs.calendar.next()
    },
    doCurrentMonth () {
      this.calendar = this.$moment().format('YYYY-MM-DD')
    },
    doPreviousMonth () {
      this.$refs.calendar.prev()
    },
    doUpdateFromCalendar () {
      this.activeCalendar.title = this.$refs.calendar.title
      this.activeCalendar.start = this.$moment(this.$refs.calendar.lastStart.date).toDate()
      this.activeCalendar.end = this.$moment(this.$refs.calendar.lastEnd.date).toDate()
    }
  },
  mounted () {
    this.doLoad()
  },
  computed: {
    calendarEvents () {
      const events = []

      this.events.courses.forEach(event => {
        events.push({
          name: event.course.name,
          organization: event.course.organization,
          location: event.location,
          courseId: event.courseId,
          start: this.$moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
          color: '#16a085',
          timed: true,
          type: 'course'
        })
      })

      this.events.trips.forEach(event => {
        if ('dates' in event) {
          event.dates.forEach(date => {
            events.push({
              name: event.name,
              tripId: event.id,
              start: this.$moment(date.start).format('YYYY-MM-DD'),
              end: this.$moment(date.end).format('YYYY-MM-DD'),
              color: '#e67e22',
              timed: false,
              type: 'trip'
            })
          })
        }
      })

      return events
    },
    groupedCourseEvents () {
      const grouped = []

      this.events.courses.forEach(event => {
        const groupedCourseEvent = {
          groupUuid: event.groupUuid,
          organization: event.course.organization,
          name: event.course.name,
          events: [event]
        }

        let found = false

        grouped.forEach((groupedEvent, index) => {
          if (groupedEvent.groupUuid === event.groupUuid) {
            found = true
            grouped[index].events.push(event)
          }
        })

        if (!found) {
          grouped.push(groupedCourseEvent)
        }
      })

      return grouped
    }
  },
  created () {
    this.doSetDocumentTitle('Calendar Events', true)
  }
}
</script>

<style scoped>
::v-deep a {
  color: white !important;
}
</style>
